export default mimeType => {
  if (mimeType.indexOf("spreadsheetml") !== -1) {
    return ".xlsx";
  } else if (mimeType.indexOf("jpeg") !== -1) {
    return ".jpeg";
  } else if (mimeType.indexOf("jpg") !== -1) {
    return ".jpg";
  } else if (mimeType.indexOf("png") !== -1) {
    return ".png";
  } else if (mimeType.indexOf("text") !== -1) {
    return ".txt";
  } else if (mimeType.indexOf("wordprocessingml") !== -1) {
    return ".docx";
  } else if (mimeType.indexOf("pdf") !== -1) {
    return ".pdf";
  } else {
    return ".zip";
  }
};
